import React from "react"
import {graphql} from "gatsby";
import Seo from "../components/molecules/Seo";
import Pagination from "../components/organisms/Pagination";
import Header from "../components/molecules/Header";
import HrHeading from "../components/atoms/HrHeading";
import Container from "../components/atoms/Container";
import NewsletterCard from "../components/molecules/NewsletterCard";
import ColorScheme from "../components/atoms/ColorScheme";

function NewsletterArchive({data, pageContext}) {
  const newsletters = data.allPrismicNewsletter.nodes;
  const {primary, secondary} = newsletters[0].data;

  return (
    <ColorScheme
      primary={primary}
      secondary={secondary}>
      <Seo
        title="Back issues"
        image={newsletters[0].data.og_image && newsletters[0].data.og_image.url} />
      <Header title="Archive"/>
      <HrHeading>
        <h2 className="h3">Back issues:</h2>
      </HrHeading>
      <Container>
        {newsletters && newsletters.map(newsletter => <NewsletterCard key={newsletter.uid} newsletter={newsletter} />)}
      </Container>
      <Pagination totalPages={pageContext.numNLetterPages} currentPage={pageContext.currentPage} baseSlug="archive/"/>
    </ColorScheme>
  )
}

export default NewsletterArchive

export const pageQuery = graphql`
  query NewsletterArchiveQuery($skip: Int!, $limit: Int!) {
    allPrismicNewsletter(
      sort: {order: DESC, fields: first_publication_date}
      limit: $limit
      skip: $skip
    ) {
      nodes {
        uid
        data {
            primary
            secondary
            og_image {
              url(imgixParams: {height: 630, width: 1200})
            }
            og_description {
              text
            }
            edition {
              text
            }
            date {
              text
            }
            conclusion {
              raw
            }
            body {
            ... on PrismicNewsletterDataBodySection {
              id
              primary {
                curator1 {
                  document {
                    ... on PrismicContributor {
                      id
                      data {
                        name {
                          text
                        }
                      }
                      url
                    }
                  }
                }
                section_title {
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`
